<template>
  <div>
    <skeleton v-if="!banner" type="actList" />
    <div v-else :style="{ background: banner == '' ? '' : bgColor }">
      <img :src="image" alt="">
      <img v-if="title != ''" class="tit" :src="title">
      <skeleton v-if="list.length === 0" type="list" />
      <div v-else>
        <div v-for="(lis, index) in list" :key="index">
          <div v-if="isShowCategory == 1">
            <div
              v-show="id != 531 && id != 521"
              :style="{ height: lis.teamHeight / 2 + 'px', overflow: 'hidden' }"
            >
              <img v-show="id != 416" :src="lis.teamBanner" alt="">
            </div>
            <van-tabs
              v-model="active"
              sticky
              :background="bgColor"
              title-active-color="fff"
              title-inactive-color="#fff"
              color="rgba(255,255,255,1)"
              line-width="30px"
              @change="changeJdTab"
            >
              <van-tab
                v-for="(cate, cI) in categoryInfo"
                :key="cI"
                ref="VantTabs"
                :name="cate.id"
                :title="cate.name"
              >
                <!--模版1-->
                <div
                  v-if="lis.teamType == 1"
                  class="activety_list activety_list1"
                >
                  <div
                    v-for="(jx, jIndex) in lis.goodsInfo"
                    :key="jIndex"
                    class="activety_item"
                    @click="godetail(jx)"
                  >
                    <img class="img1" :src="jx.goodCover">
                    <!-- <imgLoading :src="jx.goodCover"/> -->
                    <h1>{{ jx.goodName }}</h1>

                    <div class="empty-content">
                      <div v-if="jx.growthValueNew.share" class="desc_one">
                        分享赚{{ jx.growthValueNew.share }}
                      </div>
                    </div>
                    <div class="PriceBox">
                      <div class="Price">
                        <em>¥</em>{{ jx.goodVipPrice
                        }}<span><em>¥</em>{{ jx.goodPrice }}</span>
                      </div>
                    </div>

                    <!-- <p>
                <em>¥</em>{{ jx.goodVipPrice
                }}<span><em>¥</em>{{ jx.goodPrice }}</span>
              </p> -->
                  </div>
                </div>

                <!--模版二-->
                <div
                  v-if="lis.teamType == 2"
                  class="activety_list activety_list2"
                >
                  <div
                    v-for="(jx, jIndex) in lis.goodsInfo"
                    :key="jIndex"
                    class="activety_item"
                    @click="godetail(jx)"
                  >
                    <img :src="jx.goodCover">
                    <!-- <imgLoading :src="jx.goodCover"/> -->
                    <h1>{{ jx.goodName }}</h1>
                    <div class="empty-content">
                      <div v-if="jx.growthValueNew.share" class="desc_one">
                        分享赚{{ jx.growthValueNew.share }}
                      </div>
                    </div>
                    <div class="PriceBox">
                      <div class="Price">
                        <em>¥</em>{{ jx.goodVipPrice
                        }}<span><em>¥</em>{{ jx.goodPrice }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!--模版三 9.9-->
                <div
                  v-if="lis.teamType == 3"
                  class="activety_list activety_list3"
                >
                  <div
                    v-for="(jx, jIndex) in lis.goodsInfo"
                    :key="jIndex"
                    class="activety_item"
                    @click="godetail(jx)"
                  >
                    <img :src="jx.goodCover" class="activety_img3">

                    <h1>{{ jx.goodName }}</h1>

                    <div class="empty-content">
                      <div v-if="jx.growthValueNew.share" class="desc_one">
                        分享赚{{ jx.growthValueNew.share }}
                      </div>
                    </div>
                    <div class="PriceBox">
                      <div class="Price">
                        <em>¥</em>{{ jx.goodVipPrice
                        }}<span><em>¥</em>{{ jx.goodPrice }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!--模版四-->
                <div class="activity_box">
                  <div
                    v-if="lis.teamType == 4"
                    class="activety_list activety_list4"
                    :style="'width:' + lis.width + 'px'"
                  >
                    <div
                      v-for="(jx, jIndex) in lis.goodsInfo"
                      :key="jIndex"
                      class="activety_item"
                      @click="godetail(jx)"
                    >
                      <!-- <imgLoading :src="jx.goodCover"/> -->
                      <img :src="jx.goodCover">
                      <h1>{{ jx.goodName }}</h1>

                      <div class="empty-content">
                        <div v-if="jx.growthValueNew.share" class="desc_one">
                          分享赚{{ jx.growthValueNew.share }}
                        </div>
                      </div>
                      <div class="PriceBox">
                        <div class="Price">
                          <em>¥</em>{{ jx.goodVipPrice
                          }}<span><em>¥</em>{{ jx.goodPrice }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </van-tab>
            </van-tabs>
          </div>

          <div v-if="isShowCategory == 0">
            <div
              v-show="id != 531 && id != 521"
              :style="{ height: lis.teamHeight / 2 + 'px', overflow: 'hidden' }"
            >
              <img v-show="id != 416" :src="lis.teamBanner" alt="">
            </div>

            <!--模版1-->
            <div v-if="lis.teamType == 1" class="activety_list activety_list1">
              <div
                v-for="(jx, jIndex) in lis.goodsInfo"
                :key="jIndex"
                class="activety_item"
                @click="godetail(jx)"
              >
                <img class="img1" :src="jx.goodCover">
                <!-- <imgLoading :src="jx.goodCover"/> -->
                <h1>{{ jx.goodName }}</h1>
                <div
                  v-if="jx.growthValueNew.buy"
                  class="empty-content growthValues"
                >
                  <img src="@/assets/images/feng-ye.png">
                  成长值{{ jx.growthValueNew.buy }}
                </div>
                <!-- <div class="empty-content">
                  <div v-if="jx.growthValueNew.buy" class="desc_one">
                    成长值{{ jx.growthValueNew.buy }}
                  </div>
                </div> -->
                <div class="PriceBox">
                  <div class="Price">
                    <em>¥</em>{{ jx.goodVipPrice
                    }}<span><em>¥</em>{{ jx.goodPrice }}</span>
                  </div>
                </div>

                <!-- <p>
                <em>¥</em>{{ jx.goodVipPrice
                }}<span><em>¥</em>{{ jx.goodPrice }}</span>
              </p> -->
              </div>
            </div>
            <!--模版二-->
            <div v-if="lis.teamType == 2" class="activety_list activety_list2">
              <div
                v-for="(jx, jIndex) in lis.goodsInfo"
                :key="jIndex"
                class="activety_item"
                @click="godetail(jx)"
              >
                <img :src="jx.goodCover">
                <img class="iconyouxuan" :src="jx.iconYouXuan" alt="">
                <!-- <imgLoading :src="jx.goodCover"/> -->
                <h1>{{ jx.goodName }}</h1>
                <div class="empty-content">
                  <div v-if="jx.growthValueNew.share" class="desc_one">
                    分享赚{{ jx.growthValueNew.share }}
                  </div>
                </div>
                <div class="PriceBox">
                  <div class="Price">
                    <em>¥</em>{{ jx.goodVipPrice
                    }}<span><em>¥</em>{{ jx.goodPrice }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!--模版三 9.9-->

            <div v-if="lis.teamType == 3" class="activety_list activety_list3">
              <div
                v-for="(jx, jIndex) in lis.goodsInfo"
                :key="jIndex"
                class="activety_item"
                @click="godetail(jx)"
              >
                <img :src="jx.goodCover" class="activety_img3">
                <!-- <imgLoading :src="jx.goodCover"  class="activety_img3"/> -->
                <h1>{{ jx.goodName }}</h1>

                <div class="empty-content">
                  <div v-if="jx.growthValueNew.share" class="desc_one">
                    分享赚{{ jx.growthValueNew.share }}
                  </div>
                </div>
                <div class="PriceBox">
                  <div class="Price">
                    <em>¥</em>{{ jx.goodVipPrice
                    }}<span><em>¥</em>{{ jx.goodPrice }}</span>
                  </div>
                </div>

                <!-- <p>
                <em>¥</em>{{ jx.goodVipPrice
                }}<span><em>¥</em>{{ jx.goodPrice }}</span>
              </p> -->
              </div>
            </div>

            <!--模版四-->
            <div class="activity_box">
              <div
                v-if="lis.teamType == 4"
                class="activety_list activety_list4"
                :style="'width:' + lis.width + 'px'"
              >
                <div
                  v-for="(jx, jIndex) in lis.goodsInfo"
                  :key="jIndex"
                  class="activety_item"
                  @click="godetail(jx)"
                >
                  <!-- <imgLoading :src="jx.goodCover"/> -->
                  <img :src="jx.goodCover">
                  <h1>{{ jx.goodName }}</h1>

                  <div class="empty-content">
                    <div v-if="jx.growthValueNew.share" class="desc_one">
                      分享赚{{ jx.growthValueNew.share }}
                    </div>
                  </div>
                  <div class="PriceBox">
                    <div class="Price">
                      <em>¥</em>{{ jx.goodVipPrice
                      }}<span><em>¥</em>{{ jx.goodPrice }}</span>
                    </div>
                  </div>

                  <!-- <p>
                  <em>¥</em>{{ jx.goodVipPrice
                  }}<span><em>¥</em>{{ jx.goodPrice }}</span>
                </p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getActivityList,
  activityShareInfo,
  getActivityCategoryInfo
} from '@/services/activity.js'
import { urlConversion } from '@/utils/public.js'
import skeleton from '@/component/skeleton/index'
import Vue from 'vue'
import { Empty, Icon, Lazyload, Tab, Tabs, Toast } from 'vant'

Vue.use(Empty).use(Tab).use(Tabs).use(Lazyload).use(Empty).use(Icon)

export default {
  name: 'Acthds',
  components: {
    skeleton
  },
  data() {
    return {
      isShowCategory: 0,
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: document.title,
        callbackGo: this.callbackGo
      },
      goodinfo: null,
      active: '',
      categoryInfo: '',
      teamHeight: 0,
      banner: '',
      title: '',
      bgColor: 'd71f28',
      valuetext: ',mmkll',
      list: [],
      id: '', // 活动id
      image: '' // banner img
      // categoryId: 0,
    }
  },
  created() {
    // this.getActivityList(0);
  },
  mounted() {
    window.RNshareDataChange = this.RNshareDataChange
    // this.RNshareDataChange()
    this.gitActhds()
  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    // 注入分享方法
    RNshareDataChange() {
      this.$nextTick(() => {
        const mid = this.$route.query.mid

        if (mid && mid !== '0') {
          const body = {
            uid: mid, // 用户ID
            type: this.$route.query.id, // 活动ID
            shareType: 7 // 分享类型 默认为7
          }
          // 获取活动分享到小程序和分享到海报信息
          activityShareInfo(body).then((res) => {
            if (Number(res.code) === 200) {
              const obj = urlConversion('obj', window.location.href)
              const reCode = res.data.reCode
              const arr = []
              let _url = ''
              let pathUrl = ''
              for (const key in obj.value) {
                if (key !== 'env') {
                  arr.push(key + '=' + obj.value[key])
                }
              }
              _url = obj.Http + '?' + arr.join('&')
              pathUrl =
                '/page/mall/pages/SpecialEvent/SpecialEvent?reCode=' +
                reCode +
                '&id=' +
                this.$route.query.id

              const _Router = {
                url: _url,
                eventName: 'share',
                image: res.data.showImg,
                posterImage: res.data.img,
                title: res.data.title,
                des: res.data.remind,
                path: pathUrl
              }
              this.$store.dispatch('RNrouter', _Router)
            }
          })
        } else {
          const RNloginData = {
            eventName: 'login'
          }
          this.$store.dispatch('RNrouter', RNloginData)
        }
      })
    },

    // 获取 teamBanner 信息 图片
    gitActhds() {
      const params = {
        id: this.$route.query.id
      }
      getActivityCategoryInfo(params).then((res) => {
        if (Number(res.code) === 200) {
          if (document) {
            document.title = res.data.activityInfo.activityName
          }

          this.image = res.data.activityInfo.backGround.url
          this.bgColor = res.data.activityInfo.backColor
          this.categoryInfo = res.data.categoryInfo
          this.isShowCategory = res.data.isShowCategory
          if (res.data.isShowCategory !== 0) {
            this.getActivityList(res.data.categoryInfo[0].id)
          } else {
            this.getActivityList(0)
          }
        } else {
          Toast(res.msg)
        }
      })
    },
    changeJdTab: function(id) {
      this.categoryId = id
      this.list = []
      this.getActivityList(id)
    },
    // 获取信息
    getActivityList(categoryId, type) {
      this.id = this.$route.query.id
      const opt = {
        id: this.id,
        categoryId: categoryId,
        page: 1,
        pageSize: 9999
      }
      // delete opt.categoryId

      getActivityList(opt).then((res) => {
        if (res.data.length === 0) {
          this.list = res.data
          return
        }
        // this.gitActhds();

        if (!type) {
          this.teamHeight = res.data[0].teamHeight / 2
          this.banner = res.data[0].teamBanner
        }
        this.list = res.data
        this.list.map((item) => {
          item.width = 120 * (item.goodsInfo.length + 1)
        })
      })
    },
    showSku(goodinfo) {
      this.goodinfo = goodinfo
      this.$store.commit('changeShowSku', true)

      // if (window.localStorage.getItem("uid")) {
      //   this.$store.commit("changeShowSku", true);
      // } else {
      //   this.$store.dispatch("loginIntercept", this.$route.query);
      // }
    },
    // 跳转商品详情
    godetail(item) {
      const obj = item
      obj.product_type = 1
      this.$store.dispatch('godetail', obj)
    }
  }
}
</script>
<style lang="less" scoped>
.tit {
  display: block;
}

/deep/ .van-tab--active {
  color: #fff;
}

.activety_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0 15px;
  width: 355px;
  margin: 0 auto;
  text-align: left;
  .activety_item {
    .PriceBox {
      overflow: hidden;
      // height: 20px;
      line-height: 20px;
      padding: 10px 12px;

      .Price {
        float: left;
        width: 70%;
        height: 20px;

        color: #ff4545;
        // padding: 0 12px;
        text-align: left;
        font-size: 13px;

        span {
          font-size: 12px;
          color: #959595;
          text-decoration: line-through;
          margin-left: 2px;
        }

        em {
        }
      }

      .addCitr {
        float: right;
        width: 20px;
        height: 20px;
        font-size: 16px;
        background: red;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
      }
    }
    position: relative;
    overflow: hidden;
    width: 175px;
    height: 275px;
    border-radius: 5px;
    background: #fff;
    margin-top: 5px;
    .empty-content {
      height: 18px;
    }
    .desc_one {
      margin: 0 10px;
      color: red;
      font-size: 0.26667rem;
      background: rgba(212, 146, 146, 0.4);
      display: initial;
      border: 0.02667rem solid red;
      border-radius: 0.10667rem;
      padding: 0 0.10667rem;
      box-sizing: border-box;
    }
    .iconyouxuan {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      width: 70px !important;
      height: 70px !important;
    }
    img {
      width: 175px;
      height: 164px;
    }

    h1 {
      font-size: 12px;
      line-height: 15px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /*要显示的行数*/
      -webkit-box-orient: vertical;
      padding: 0 12px;
      height: 0.8rem;
      text-align: left;
    }

    p {
      color: #ff4545;
      padding: 0 12px;
      text-align: left;
      font-size: 13px;

      span {
        font-size: 12px;
        color: #959595;
        text-decoration: line-through;
      }

      em {
        font-size: 10px;
      }
    }
  }

  .activety_item:nth-child(odd) {
    margin-right: 5px;
  }
}

.activety_list1 {
  .activety_item {
    width: 355px;
    //max-height: 264px;
    height: 100%;
    margin-top: 10px;
    padding-bottom: 12px;
    box-sizing: border-box;
    .growthValues {
      display: inline-block;
      margin-left: 0.32rem;
      height: 22px;
      padding: 0 5px;
      background: #1a1716;
      border-radius: 11px;
      line-height: 22px;
      color: #fff;
      font-size: 12px;
      font-family: PingFang SC Regular, PingFang SC Regular-Regular;
      font-weight: 400;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        float: left;
        margin-top: 2px;
      }
    }
    .img1 {
      width: 355px;
      height: auto;
    }
  }

  .activety_item:nth-child(odd) {
    margin-right: 0;
  }
}

.activety_list3 {
  padding: 0 0 15px;

  .activety_item {
    width: 115px;
    height: 100%;
    margin-right: 5px;
    .iconyouxuan {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      width: 70px !important;
      height: 70px !important;
    }
    img {
      width: 115px;
      height: 120px;
    }
  }

  .activety_item:nth-child(3n) {
    margin-right: 0;
  }
}

.activity_box {
  padding: 0 5px;
  overflow: scroll;
}

.activity_box {
  overflow-x: scroll;
}

.activety_list4 {
  width: 100%;

  .activety_item {
    width: 115px;
    height: 100%;
    // height: 190px;
    margin-right: 5px;
    float: left;
    overflow: hidden;

    img {
      width: 115px;
      height: 120px;
    }
  }

  .activety_item:nth-child(odd) {
    margin-right: 5px;
  }
}

// /deep/.van-tabs__wrap {
//   height: 36px;
//   .van-tabs__nav {
//     background: #d71f28 !important;

//     .van-tab {
//       color: #fff;
//     }
//     .van-tab--active {
//       color: #fff !important;
//       background: #d71f28;
//     }
//   }
// }
// /deep/.van-tabs__wrap {
//   &::after {
//     // border: 0 solid #ebedf0;
//     border: none;
//   }
// }
</style>
